
export interface IApiEndpointProvider {
  createCustomerAccount: string;
  createOnlineTicketOrder: string;
  createOnlineProductOrder: string;
  markOrderWithFailedPayment: string;
  abortOrder: string;
  lockSeatSelection: string,
  createPoiReview: string,
  createClientFromCustomerAccount: string,
  validateTaxForm: string,
  fetchArtistPageInfo: string;
  fetchBrandPageInfo: string;
  fetchOrganizerPageInfo: string;
  fetchNfcPassPageInfo: string;
  isSubscribingToBusinessLevelMarketingEnabled: string;
  fetchBusinessLevelMarketingInfo: string;
}

export const ApiEndpointProvider: IApiEndpointProvider = {
  createCustomerAccount: 'createCustomerAccount',
  createOnlineTicketOrder: 'createOnlineTicketOrder',
  createOnlineProductOrder: 'createOnlineProductOrder',
  markOrderWithFailedPayment: 'markOrderWithFailedPayment',
  abortOrder: 'abortOrder',
  lockSeatSelection: 'lockSeatSelection',
  createPoiReview: 'createPoiReview',
  createClientFromCustomerAccount: 'createClientFromCustomerAccount',
  validateTaxForm: 'validateTaxForm',
  fetchArtistPageInfo: 'fetchArtistPageInfo',
  fetchBrandPageInfo: 'fetchBrandPageInfo',
  fetchOrganizerPageInfo: 'fetchOrganizerPageInfo',
  fetchNfcPassPageInfo: 'fetchNfcPassPageInfo',
  isSubscribingToBusinessLevelMarketingEnabled: 'isSubscribingToBusinessLevelMarketingEnabled',
  fetchBusinessLevelMarketingInfo: 'fetchBusinessLevelMarketingInfo',
}

export const EmulatorApiEndpointProvider: IApiEndpointProvider = {
  createCustomerAccount: 'accounts/createCustomerAccount',
  createOnlineTicketOrder: 'orders/createOnlineTicketOrder',
  createOnlineProductOrder: 'orders/createOnlineProductOrder',
  markOrderWithFailedPayment: 'orders/markOrderWithFailedPayment',
  abortOrder: 'orders/abortOrder',
  lockSeatSelection: 'events/lockSeatSelection',
  createPoiReview: 'reviews/createPoiReview',
  createClientFromCustomerAccount: 'accounts/createClientFromCustomerAccount',
  validateTaxForm: 'orders/validateTaxForm',
  fetchArtistPageInfo: 'public/fetchArtistPageInfo',
  fetchBrandPageInfo: 'public/fetchBrandPageInfo',
  fetchOrganizerPageInfo: 'public/fetchOrganizerPageInfo',
  fetchNfcPassPageInfo: 'passes/fetchNfcPassPageInfo',
  isSubscribingToBusinessLevelMarketingEnabled: 'marketing/isSubscribingToBusinessLevelMarketingEnabled',
  fetchBusinessLevelMarketingInfo: 'marketing/fetchBusinessLevelMarketingInfo',
}
